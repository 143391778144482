import React from "react";
import {
  SEO,
  PageLayout,
  Section,
  FadeReveal,
  Image,
  MarkdownContent,
  MultipleComponentIterator,
  SlideReveal,
  FullImageCard,
  InternalLink,
  FishermanIcon,
  BasicCarousel,
  componentIterator,
  multipleComponentIterator,
  ReviewV2,
  CustomerReviewModal,
  OutboundLink,
  SocialMediaV2,
  Location,
  Email,
  Phone,
  HoursOfOperation,
} from "@bluefin/components";
import { Grid, Header, Button, Card } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
  withNamedArgs,
  getBusinessFiles,
} from "../utils/utils";
import { createServiceCategorySlug } from "@bluefin/gatsby-plugin-fisherman-page-creator";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessMenuCategory,
      allFishermanBusinessFile,
      allFishermanBusinessLocation,
      instagramSocialMedia,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          title={fishermanBusinessWebsitePage.seoTitle}
        />
        <PageLayout hero={false} subfooter={false} className={"home-page"}>
          <Section className={"default-hero-section"}>
            <Grid>
              <Grid.Column width={10} className={"content-and-images"}>
                <Grid>
                  <Grid.Row className={"images"}>
                    <Grid.Column width={7} className={"left"}>
                      <FadeReveal duration={1250} triggerOnce={true} delay={0}>
                        <Image
                          src={getComponentFiles({
                            components: fishermanBusinessWebsitePage.components,
                            componentName: "HeroElement",
                            numToSelect: 1,
                            selectionOffset: 0,
                          })}
                          loadStrategy={"eager"}
                        />
                      </FadeReveal>
                    </Grid.Column>
                    <Grid.Column width={9} className={"right"}>
                      <FadeReveal
                        duration={1250}
                        triggerOnce={true}
                        delay={450}
                      >
                        <Image
                          src={getComponentFiles({
                            components: fishermanBusinessWebsitePage.components,
                            componentName: "HeroElement",
                            numToSelect: 1,
                            selectionOffset: 1,
                          })}
                          loadStrategy={"eager"}
                        />
                      </FadeReveal>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row className={"content"}>
                    <Grid.Column>
                      <Header
                        as={"h1"}
                        content={getComponentContentNodeContent({
                          components: fishermanBusinessWebsitePage.components,
                          componentId: "home_hero_header",
                          defaultValue: "Enhancing Your Natural Beauty",
                        })}
                      />
                      <MarkdownContent
                        content={getComponentContentNodeContent({
                          components: fishermanBusinessWebsitePage.components,
                          componentId: "home_hero_description",
                          defaultValue:
                            "The ultimate destination for beauty and self-care",
                        })}
                      />
                      <div className={"ctas-container"} />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
              <Grid.Column width={6} className={"image"}>
                <FadeReveal duration={1250} triggerOnce={true} delay={900}>
                  <Image
                    src={getComponentFiles({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "HeroElement",
                      numToSelect: 1,
                      selectionOffset: 2,
                    })}
                    loadStrategy={"eager"}
                  />
                </FadeReveal>
              </Grid.Column>
            </Grid>
          </Section>
          <Section className={"services-section"}>
            <Header
              as={"h2"}
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "services_section_header",
                defaultValue: "Our Services",
              })}
            />
            <MarkdownContent
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "services_section_description",
              })}
            />
            <Card.Group itemsPerRow={4}>
              <MultipleComponentIterator
                iterator={allFishermanBusinessMenuCategory.nodes}
                limit={8}
                components={[
                  {
                    component: (
                      <SlideReveal
                        className={"ui card"}
                        direction={"up"}
                        triggerOnce={true}
                        fade={true}
                      />
                    ),
                    propMap: { key: "_id" },
                    children: [
                      {
                        component: (
                          <FullImageCard
                            as={InternalLink}
                            link={true}
                            event={{
                              category: "Services",
                              action: "View Category Details",
                            }}
                            className={"category-card"}
                          />
                        ),
                        propMap: {
                          image: withNamedArgs({
                            func: getBusinessFiles,
                            args: {
                              businessFiles: allFishermanBusinessFile.nodes,
                              numToSelect: 1,
                            },
                          }),
                          "event.label": "name",
                          to: createServiceCategorySlug,
                        },
                        children: [
                          {
                            component: <Card.Header />,
                            children: [
                              {
                                component: <span />,
                                propMap: { children: "name" },
                              },
                              {
                                component: (
                                  <FishermanIcon iconName={"arrow-right"} />
                                ),
                              },
                            ],
                          },
                          {
                            component: (
                              <MarkdownContent className={"description"} />
                            ),
                            propMap: { content: "description" },
                          },
                        ],
                      },
                    ],
                  },
                ]}
              />
            </Card.Group>
            <div className={"see-all-services-container"}>
              <Button
                primary={true}
                event={{ category: "Services", action: "View All Services" }}
                as={InternalLink}
                to={"/services/"}
              >
                <span
                  children={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "see_all_services_button",
                    defaultValue: "Discover More Services",
                  })}
                />
              </Button>
            </div>
          </Section>
          <Section className={"about-section"}>
            <Grid stackable={true}>
              <Grid.Column width={8}>
                <FadeReveal duration={1250} triggerOnce={true} delay={0}>
                  <div>
                    <Header
                      as={"h2"}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "about_section_header",
                        defaultValue: "About Us",
                      })}
                    />
                    <Header
                      as={"h3"}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "about_section_subheader",
                      })}
                    />
                    <MarkdownContent
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "about_section_description",
                      })}
                    />
                    <Button
                      primary={true}
                      to={"/about/"}
                      as={InternalLink}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "see_more_about_button",
                        defaultValue: "Learn More About Us",
                      })}
                      event={{ category: "About", action: "View About" }}
                    />
                  </div>
                </FadeReveal>
              </Grid.Column>
              <Grid.Column width={8}>
                <FadeReveal duration={1250} triggerOnce={true} delay={450}>
                  <BasicCarousel
                    className={"about-section-image-carousel"}
                    size={1}
                    slide={true}
                    displayIndicators={false}
                    displayPlayPauseButton={true}
                    autoScroll={false}
                    leftArrowIcon={"arrow-left"}
                    rightArrowIcon={"arrow-right"}
                    mobile={{ size: 1 }}
                    items={componentIterator({
                      iterator: getComponentFiles({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "ImageCollection",
                        componentIdentifier: "about_section_images",
                      }),
                      component: (
                        <Image
                          background={true}
                          className={"single-item-carousel-image"}
                        />
                      ),
                      propMap: { src: "__all__" },
                    })}
                  />
                </FadeReveal>
              </Grid.Column>
            </Grid>
          </Section>
          <Section className={"reviews-section"}>
            <Image
              background={true}
              src={getComponentFiles({
                components: fishermanBusinessWebsitePage.components,
                componentIdentifier: "reviews_section_background_image",
                numToSelect: 1,
              })}
            >
              <Header
                as={"h2"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "reviews_section_header",
                  defaultValue: "What Our Clients Say",
                })}
              />
              <BasicCarousel
                size={1}
                displayIndicators={true}
                stepSize={1}
                autoScroll={false}
                leftArrowIcon={"arrow-left"}
                rightArrowIcon={"arrow-right"}
                displayPlayPauseButton={false}
                slide={true}
                items={multipleComponentIterator({
                  iterator: fishermanBusiness.reviews,
                  components: [
                    {
                      component: <ReviewV2 />,
                      propMap: { key: "_id" },
                      children: [
                        {
                          component: <ReviewV2.Text charLimit={440} />,
                          propMap: { text: "text" },
                        },
                        {
                          component: <ReviewV2.Line />,
                          children: [
                            {
                              component: <ReviewV2.Rating />,
                              propMap: { rating: "rating" },
                            },
                            {
                              component: <ReviewV2.RatingLabel />,
                              propMap: { rating: "rating" },
                            },
                          ],
                        },
                        {
                          component: <ReviewV2.Line />,
                          children: [
                            {
                              component: <ReviewV2.Author />,
                              propMap: { author: "author" },
                            },
                            {
                              component: <ReviewV2.Source />,
                              propMap: { source: "source", link: "link" },
                            },
                          ],
                        },
                      ],
                    },
                  ],
                })}
              />
              <CustomerReviewModal
                businessId={fishermanBusiness._id}
                locations={allFishermanBusinessLocation.nodes}
                businessType={fishermanBusiness.type}
                triggerButtonProps={{ secondary: true }}
                buttonText={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "submit_review_button",
                  defaultValue: "Submit Review",
                })}
              />
            </Image>
          </Section>
          <Section className={"social-media-section"}>
            <div className={"header-container"}>
              <Header
                as={"h2"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "social_media_section_header",
                  defaultValue: "Follow Us",
                })}
              />
              <Button
                as={OutboundLink}
                to={instagramSocialMedia.link}
                event={{ category: "Social", action: "Follow Us on Instagram" }}
                aria-label={"Follow Us on Instagram"}
                basic={true}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "social_media_section_follow_us_button",
                  defaultValue: "Follow Us",
                })}
                icon={<FishermanIcon iconName={"arrow-right"} />}
              />
            </div>
            <Card.Group itemsPerRow={6}>
              <MultipleComponentIterator
                iterator={getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "ImageCollection",
                  componentIdentifier: "social_media_section_images",
                })}
                components={[
                  {
                    component: (
                      <SlideReveal
                        className={"ui card"}
                        fade={true}
                        direction={"up"}
                        triggerOnce={true}
                      />
                    ),
                    propMap: { key: "_id" },
                    children: [
                      {
                        component: <FullImageCard />,
                        propMap: { image: "__all__" },
                      },
                    ],
                  },
                ]}
              />
            </Card.Group>
            <SocialMediaV2>
              <MultipleComponentIterator
                iterator={fishermanBusiness.socialMedia}
                components={[
                  {
                    component: <SocialMediaV2.Platform />,
                    propMap: { key: "_id", url: "link", platform: "type" },
                    children: [
                      {
                        component: <SocialMediaV2.Icon />,
                        propMap: { icon: "type" },
                      },
                    ],
                  },
                ]}
              />
            </SocialMediaV2>
          </Section>
          <Section className={"locations-section"}>
            <Grid columns={2} stackable={true}>
              <Grid.Column width={8} className={"carousel-column"}>
                <FadeReveal duration={1250} triggerOnce={true} delay={0}>
                  <BasicCarousel
                    className={"locations-section-image-carousel"}
                    size={1}
                    slide={true}
                    displayIndicators={false}
                    displayPlayPauseButton={false}
                    autoScroll={false}
                    mobile={{ size: 1 }}
                    items={componentIterator({
                      iterator: getComponentFiles({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "ImageCollection",
                        componentIdentifier: "locations_section_images",
                      }),
                      component: (
                        <Image
                          background={true}
                          className={"single-item-carousel-image"}
                        />
                      ),
                      propMap: { src: "__all__" },
                    })}
                  />
                </FadeReveal>
              </Grid.Column>
              <Grid.Column width={8} className={"information-column"}>
                <FadeReveal duration={1250} triggerOnce={true} delay={450}>
                  <div className={"information-content"}>
                    <Header
                      as={"h2"}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "locations_section_header",
                      })}
                    />
                    <Header
                      as={"h3"}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "locations_section_subheader",
                      })}
                    />
                    <MarkdownContent
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "locations_section_description",
                      })}
                    />
                    <Button
                      primary={true}
                      as={InternalLink}
                      to={"/contact/"}
                      content={"Contact Us"}
                    />
                  </div>
                  <Card.Group className={"locations-cards"}>
                    <MultipleComponentIterator
                      iterator={allFishermanBusinessLocation.nodes}
                      components={[
                        {
                          component: <Card />,
                          propMap: { key: "_id" },
                          children: [
                            {
                              component: <Card.Content />,
                              children: [
                                {
                                  component: <Card.Header />,
                                  propMap: { content: "name" },
                                },
                                {
                                  component: <Card.Description />,
                                  children: [
                                    {
                                      component: <Location inline={true} />,
                                      propMap: {
                                        "address.street": "street",
                                        "address.city": "city",
                                        "address.state": "state",
                                        "address.zip": "zipCode",
                                      },
                                    },
                                    {
                                      component: <Email />,
                                      propMap: { email: "email" },
                                    },
                                    {
                                      component: <Phone />,
                                      propMap: { phone: "phoneNumber" },
                                    },
                                  ],
                                },
                                {
                                  component: (
                                    <HoursOfOperation
                                      displayOption={"dailyWithPopup"}
                                    />
                                  ),
                                  propMap: { hours: "hours" },
                                },
                              ],
                            },
                          ],
                        },
                      ]}
                    />
                  </Card.Group>
                </FadeReveal>
              </Grid.Column>
            </Grid>
          </Section>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      reviews {
        _id
        author
        text
        source
        rating
        link
      }
      _id
      type
      socialMedia {
        _id
        link
        type
      }
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
      seoTitle
    }
    allFishermanBusinessMenuCategory(
      sort: { fields: [isFeatured, order], order: [DESC, ASC] }
    ) {
      nodes {
        _id
        name
        files
        description
      }
    }
    allFishermanBusinessFile {
      nodes {
        _id
        file
        description
        gatsbyFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    allFishermanBusinessLocation {
      nodes {
        _id
        street
        city
        name
        phoneNumber
        primaryLocation
        state
        zipCode
        email
        hours {
          open
          close
          day
          label
        }
      }
    }
    instagramSocialMedia: fishermanBusinessSocialMedia(
      type: { eq: "instagram" }
    ) {
      link
      type
    }
  }
`;
